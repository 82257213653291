import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"

import img404 from "../assets/404.jpg"

export default () => (
  <div>
    <Header />
    <div className="contentbox white text-center">
      <Container>
        <Row className="d-flex align-items-center">
          <Col md={4}>
            <Image src={img404} alt="404" fluid thumbnail />
          </Col>
          <Col md={8} className="text-left">
            <h1 className="text-center">404: Seite nicht gefunden</h1>
            <hr />
            Hoppla, da lief wohl etwas schief.
            <br />
            Wir wollen Sie nicht auf Ihrem Dach sitzen lassen.
            <p />
            Also schnell{" "}
            <Link className="red-link" to="/">
              ZURÜCK
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer />
  </div>
)
